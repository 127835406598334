.product-display {
  display: flex;
  margin: 0px 170px;
}

.product-display-left {
  display: flex;
  gap: 17px;
}

.product-display-img-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.product-display-img-list img {
  height: 128px;
}

.product-display-main-img {
  width: 470px;
  height: 560px;
}

.product-display-right {
  margin: 0px 70px;
  display: flex;
  flex-direction: column;
}

.product-display-right h1 {
  color: #3d3d3d;
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.3rem;
}

.product-display-right-stars {
  display: flex;
  align-items: center;
  margin-top: 13px;
  gap: 5px;
  color: #1c1c1c;
  font-size: 1.1rem;
}

.product-display-right-prices {
  display: flex;
  margin: 15px 0px;
  gap: 30px;
  font-size: 1.7rem;
  font-weight: 700;
}

.product-display-right-prices-old {
  color: #818181;
  text-decoration: line-through;
}

.product-display-right-prices-new {
  color: #ff4141;
}

.product-display-right-description {
  font-size: 1rem;
}

.product-display-right-size h1 {
  margin-top: 35px;
  color: #656565;
  font-size: 1.5rem;
  font-weight: 600;
}

.product-display-right-sizes {
  display: flex;
  margin: 10px 0px 20px 0px;
  gap: 20px;
}

.product-display-right-sizes div {
  padding: 12px 16px;
  background: #fbfbfb;
  border: 1px solid #ebebeb;
  border-radius: 3px;
  cursor: pointer;
}

.product-display-right-sizes div:hover {
  color: white;
  background: black;
}

.product-display-right button {
  padding: 25px 40px;
  width: 250px;
  font-size: 1rem;
  font-weight: 600;
  color: white;
  background: #ff4141;
  margin-bottom: 30px;
  border: none;
  outline: none;
  cursor: pointer;
}

.product-display-right button:hover {
  color: black;
  background: aquamarine;
}

.product-display-right-category {
  margin-top: 10px;
}

.product-display-right-category span {
  font-weight: 600;
}

@media (max-width: 1280px) {
  .product-display {
    margin: 0px 60px;
  }
  .product-display-left {
    gap: 10px;
  }
  .product-display-img-list {
    gap: 10px;
  }
  .product-display-img-list img {
    height: 120px;
  }
  .product-display-main-img {
    width: auto;
    height: 510px;
  }
  .product-display-right {
    margin: 0px 30px;
  }

  .product-display-right h1 {
    font-size: 1.6rem;
    line-height: unset;
  }
  .product-display-right-stars {
    gap: 3px;
    font-size: 0.9rem;
  }
  .product-display-right-prices {
    margin: 10px 0px;
    font-size: 1.4rem;
  }
  .product-display-right-description {
    font-size: 0.9rem;
  }
  .product-display-right-size h1 {
    margin-top: 20px;
    font-size: 1.3rem;
  }
  .product-display-right-sizes div {
    padding: 14px 20px;
  }
  .product-display-right button {
    width: 200px;
    padding: 20px 0px;
    margin-bottom: 20px;
  }
  .product-display-right-category {
    margin-top: 5px;
    font-size: 1rem;
  }
}

@media (max-width: 1024px) {
  .product-display {
    margin: 0px 30px;
  }
  .product-display-left {
    gap: 5px;
  }
  .product-display-img-list img {
    height: 80px;
  }
  .product-display-main-img {
    height: 350px;
  }
  .product-display-right h1 {
    font-size: 1.4rem;
  }
  .product-display-right-stars img {
    width: 15px;
  }
  .product-display-right-description {
    font-size: 0.9rem;
  }
  .product-display-right-sizes {
    padding: 10px 0px;
  }

  .product-display-right-sizes div {
    padding: 10px 16px;
    font-size: 0.8rem;
  }
  .product-display-right button {
    width: 190px;
    padding: 15px 10px;
    margin-bottom: 10px;
    font-size: 1rem;
  }
  .product-display-right-category {
    font-size: 0.8rem;
  }
}

@media (max-width: 800px) {
  .product-display {
    margin: 0px 10px;
  }
  .product-display-left {
    gap: 0px;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: start;
  }
  .product-display-img-list {
    flex-direction: row;
    gap: 6px;
  }
  .product-display-img-list img {
    height: 70px;
  }
  .product-display-main-img {
    height: 300px;
  }

  .product-display-right h1 {
    font-size: 1rem;
  }
  .product-display-right-stars img {
    width: 13px;
  }
  .product-display-right-description {
    font-size: 0.8rem;
  }
  .product-display-right-size h1 {
    margin-top: 10px 0px;
  }
  .product-display-right-sizes {
    margin-top: 10px;
    gap: 5px;
  }
  .product-display-right-sizes div {
    padding: 4px 11px;
  }
  .product-display-right button {
    width: 160px;
    padding: 18px 0px;
    font-size: 0.8rem;
  }
  .product-display-right-category {
    margin-top: 5px;
    font-size: 0.8rem;
  }
}

@media (max-width: 500px) {
  .product-display {
    flex-direction: column;
  }
  .product-display-left {
    gap: 10px;
    flex-direction: row;
    margin: auto;
  }
  .product-display-img-list {
    flex-direction: column;
    gap: 8px;
  }
  .product-display-img-list img {
    height: 75px;
  }
  .product-display-main-img {
    height: 330px;
  }
  .product-display-right {
    margin: 5px;
  }
  .product-display-right h1 {
    margin-top: 15px;
    font-size: 1.4rem;
    font-weight: 500;
  }
  .product-display-right-stars img {
    width: 15px;
  }
  .product-display-right-description {
    font-size: 0.8rem;
  }
  .product-display-right-sizes {
    gap: 10px;
    margin-top: 20px 0px;
  }
  .product-display-right-sizes div {
    padding: 10px 16px;
    font-size: 1rem;
  }
  .product-display-right button {
    width: 190px;
    padding: 20px 0px;
    font-size: 1rem;
  }
  .product-display-right-category {
    margin-top: 5px;
    font-size: 0.9rem;
  }
}
