.shop-category-banner {
  display: block;
  margin: 30px auto;
  width: 82%;
}

.shop-category-indexSort {
  display: flex;
  margin: 0px 170px;
  justify-content: space-between;
  align-items: center;
}

.shop-category-sort {
  padding: 10px 20px;
  border-radius: 40px;
  border: 1px solid #888;
}

.shop-category-sort:hover {
  background: aquamarine;
}

.shop-category-indexSort p span {
  font-weight: 600;
}

.shop-category-products {
  display: grid;
  margin: 20px 120px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 80px;
  justify-items: center;
}

.shop-category-loadmore {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 100px auto 150px auto;
  width: 233px;
  height: 69px;
  border-radius: 75px;
  background: #ededed;
  color: #787878;
  font-size: 1.1rem;
  font-weight: 500;
}

.shop-category-loadmore:hover {
  color: white;
  background: #ff6464;
}

@media (max-width: 1280px) {
  .shop-category-banner {
    margin: 20px auto;
    width: 90%;
  }
  .shop-category-indexSort {
    width: 90%;
    margin: auto;
  }
  .shop-category-sort {
    padding: 5px 10px;
    font-size: 0.8rem;
  }
  .shop-category-indexSort p {
    font-size: 0.8rem;
  }
  .shop-category-products {
    width: 90%;
    margin: 10px auto;
    row-gap: 40px;
  }
  .shop-category-products .item {
    margin: auto;
  }
  .shop-category-loadmore {
    margin: 100px auto;
    width: 200px;
    height: 60px;
    font-size: 1.2rem;
  }
}

@media (max-width: 800px) {
  .shop-category-banner {
    width: 95%;
  }
  .shop-category-products {
    width: 95%;
  }
  .shop-category-loadmore {
    margin: 60px auto;
    width: 150px;
    height: 40px;
    font-size: 0.9rem;
  }
}

@media (max-width: 500px) {
  .shop-category-products {
    grid-template-columns: 1fr 1fr;
  }

  .shop-category-loadmore {
    margin: 60px auto;
    width: 150px;
    height: 40px;
    font-size: 0.9rem;
  }
}
