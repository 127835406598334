.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin-top: 80px;
}

.footer-logo {
  display: flex;
  align-items: center;
  gap: 20px;
}

.footer-logo p {
  color: #383838;
  font-size: 2.5rem;
  font-weight: 700;
}

.footer-links {
  display: flex;
  list-style: none;
  gap: 50px;
  color: #252525;
  font-size: 1rem;
  padding-left: 0;
}

.footer-links li {
  cursor: pointer;
}

.footer-links li:hover {
  color: #ff4141;
}

.footer-social-icon {
  display: flex;
  gap: 20px;
}

.footer-icons-container {
  padding: 10px;
  padding-bottom: 6px;
  background: #fbfbfb;
  border: 1px solid #ebebeb;
}

.footer-icons-container img:hover {
  transform: scale(1.1);
}

.footer-copyright {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  width: 100%;
  margin-bottom: 30px;
  color: #1a1a1a;
  font-size: 0.9rem;
}

.footer-copyright-hr {
  width: 80%;
  border: none;
  border-radius: 10px;
  height: 3px;
  background: #c7c7c7;
}

@media (max-width: 800px) {
  .footer {
    gap: 20px;
  }

  .footer-logo {
    gap: 10px;
    align-items: end;
  }

  .footer-logo img {
    width: 50px;
  }

  .footer-logo p {
    font-size: 1.8rem;
  }

  .footer-links {
    font-size: 0.9rem;
    gap: 15px;
  }

  .footer-social-icon img {
    width: 22px;
  }

  .footer-copyright {
    font-size: 0.8rem;
  }
}

@media (max-width: 500px) {
  .footer {
    margin-top: 0px;
  }

  .footer-logo img {
    width: 40px;
  }

  .footer-logo p {
    font-size: 1.5rem;
  }

  .footer-links {
    font-size: 0.7rem;
  }

  .footer-social-icon img {
    width: 16px;
  }

  .footer-copyright {
    font-size: 0.6rem;
  }
}
