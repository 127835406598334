.breadcrumb {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #5e5e5e;
  font-size: 1.1rem;
  font-weight: 400;
  margin: 30px 170px;
  text-transform: capitalize;
}

@media (max-width: 1280px) {
  .breadcrumb {
    margin: 30px 50px;
    font-size: 1rem;
  }
}

@media (max-width: 1024px) {
  .breadcrumb {
    margin: 30px 30px;
    font-size: 0.9rem;
  }
}

@media (max-width: 800px) {
  .breadcrumb {
    margin: 30px 10px;
    font-size: 0.8rem;
  }
}

@media (max-width: 50px) {
  .breadcrumb {
    font-size: 0.6rem;
  }
}
