.loginsignup {
  width: 100%;
  height: 80vh;
  background: #fce3fe;
  padding-top: 70px;
}

.loginsignup-container {
  width: 580px;
  height: 550px;
  background: white;
  margin: auto;
  padding: 30px 60px;
}

.loginsignup-container h1 {
  margin: 20px 0px;
}

.loginsignup-fields {
  display: flex;
  flex-direction: column;
  gap: 29px;
  margin-top: 30px;
}

.loginsignup-fields input {
  height: 60px;
  padding-left: 20px;
  border: 1px solid #c9c9c9;
  outline: none;
  color: #929292;
  font-size: 1.1rem;
}

.loginsignup-container button {
  width: 580px;
  height: 72px;
  color: white;
  background: #ff4141;
  margin-top: 30px;
  border: none;
  font-size: 1.3rem;
  font-weight: 500;
  cursor: pointer;
}

.loginsignup-login {
  margin-top: 20px;
  color: #5c5c5c;
  font-size: 1rem;
  font-weight: 500;
}

.loginsignup-login span {
  color: #ff4141;
  font-weight: 600;
  cursor: pointer;
}

.loginsignup-agree {
  display: flex;
  align-items: center;
  margin-top: 15px;
  gap: 10px;
  color: #5c5c5c;
  font-size: 0.9rem;
  font-weight: 400;
}

@media (max-width: 1280px) {
  .loginsignup {
    height: 83vh;
    padding-top: 100px;
  }
  .loginsignup-container {
    width: auto;
    max-width: 500px;
    max-height: 500px;
  }
  .loginsignup-container h1 {
    margin: 10px 0px;
  }
  .loginsignup-fields {
    gap: 20px;
    margin-top: 20px;
  }
  .loginsignup-fields input {
    width: 95%;
  }
  .loginsignup-container button {
    width: 100%;
  }
  .loginsignup-login {
    font-size: 1.1rem;
  }
  .loginsignup-agree {
    font-size: 0.9rem;
    gap: 10px;
  }
}

@media (max-width: 800px) {
  .loginsignup-container {
    padding: 20px 30px;
    max-height: 460px;
  }
  .loginsignup-agree h1 {
    font-size: 2rem;
  }
  .loginsignup-fields {
    margin-top: 20px;
  }
  .loginsignup-fields input {
    height: 50px;
  }
  .loginsignup-container button {
    height: 50px;
    font-size: 1.2rem;
  }
  .loginsignup-login {
    font-size: 1rem;
  }
  .loginsignup-agree {
    font-size: 0.7rem;
    gap: 10px;
  }
}

@media (max-width: 500px) {
  .loginsignup-fields input {
    width: 93%;
  }
}
