.cart-items {
  margin: 40px 150px;
}

.cart-items hr {
  height: 3px;
  background: #e2e2e2;
  border: 0;
}

.cart-items-format-main {
  display: grid;
  grid-template-columns: 0.5fr 2fr 1fr 1fr 1fr 1fr;
  align-items: center;
  gap: 75px;
  padding: 20px 0px;
  color: #454545;
  font-size: 1.1rem;
  font-weight: 600;
}

.cart-items-format {
  font-size: 1rem;
  font-weight: 400;
}

.carticon-product-icon {
  height: 62px;
}

.cart-items-remove-icon {
  width: 15px;
  margin: 0px 40px;
  cursor: pointer;
}

.cart-items-quantity {
  width: 64px;
  height: 50px;
  border: 2px solid #ebebeb;
  background: #fff;
}

.cart-items-down {
  display: flex;
  margin: 50px 0px;
}

.cart-items-total-item {
  display: flex;
  justify-content: space-between;
  padding: 15px 0px;
}

.cart-items-total {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 200px;
  gap: 20px;
}

.cart-items-total h1 {
  font-size: 1.7rem;
}

.cart-items-total button {
  width: 262px;
  height: 58px;
  outline: none;
  border: none;
  background: #ff5a5a;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
}

.cart-items-promocode {
  flex: 1;
  font-size: 1.1rem;
  font-weight: 600;
}

.cart-items-promocode p {
  color: #555;
}

.cart-items-promobox {
  width: 400px;
  margin-top: 15px;
  padding-left: 20px;
  height: 58px;
  background: #eaeaea;
}

.cart-items-promobox input {
  outline: none;
  border: none;
  background: transparent;
  font-size: 1.1rem;
  width: 280px;
  height: 50px;
}

.cart-items-promobox button {
  width: 116px;
  height: 58px;
  background: black;
  color: #fff;
  font-size: 1.1rem;
  cursor: pointer;
}

@media (max-width: 1280px) {
  .cart-items {
    margin: 30px 50px;
  }

  .cart-items-format-main {
    grid-template-columns: 0.5fr 3fr 0.5fr 0.5fr 0.5fr 0.5fr;
    gap: 20px;
    padding: 15px 0px;
    font-size: 1rem;
  }

  .carticon-product-icon {
    height: 50px;
  }

  .cart-items-remove-icon {
    margin: auto;
  }

  .cart-items-quantity {
    width: 40px;
    height: 30px;
  }

  .cart-items-down {
    margin: 40px 0px;
    flex-direction: column;
    gap: 60px;
  }

  .cart-items-total {
    margin: 0;
    width: 50%;
  }

  .cart-items-total button {
    max-width: 260px;
    height: 54px;
    font-size: 1.1rem;
  }

  .cart-items-promobox {
    width: auto;
    max-width: 400px;
  }

  .cart-items-promobox input {
    width: 100%;
  }

  .cart-items-promobox button {
    width: 120px;
    margin-left: -125px;
  }
}

@media (max-width: 800px) {
  .cart-items-total {
    width: 75%;
  }
}

@media (max-width: 500px) {
  .cart-items-format-main {
    display: none;
    grid-template-columns: 0.5fr 3fr 0.5fr;
    gap: 10px;
  }

  .cart-items-format-main p {
    font-size: 0.9rem;
  }

  .cart-items-format {
    display: grid;
  }

  .cart-items-total button {
    font-size: 1rem;
  }

  .cart-items-down {
    gap: 40px;
  }

  .cart-items-promocode p {
    font-size: 0.85rem;
  }
  .cart-items-promobox input {
    font-size: 1rem;
  }
}
