.hero {
  height: 90vh;
  background: linear-gradient(180deg, #fde1ff, #e1ffea22 60%);
  display: flex;
}

.hero-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  padding-left: 180px;
  line-height: 1.1;
}

.hero-left h2 {
  color: #090909;
  font-size: 1.3rem;
  font-weight: 600;
}

.hero-left p {
  color: #171717;
  font-size: 4rem;
  font-weight: 600;
}

.hero-hand-icon {
  display: flex;
  align-items: center;
  gap: 20px;
}

.hero-hand-icon img {
  width: 70px;
}

.hero-latest-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: 310px;
  height: 70px;
  border-radius: 75px;
  margin-top: 30px;
  background: #ff4141;
  color: white;
  font-size: 1.1rem;
  font-weight: 500;
}

.hero-latest-btn:hover {
  background: #ff6464;
}

.hero-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hero-right img {
  width: 28vw;
}

@media (max-width: 1280px) {
  .hero {
    height: 60vh;
    margin-bottom: 50px;
  }

  .hero-left {
    padding-left: 100px;
  }

  .hero-left h2 {
    font-size: 1.3rem;
  }

  .hero-left p {
    font-size: 3rem;
  }

  .hero-hand-icon img {
    width: 50px;
  }
  .hero-latest-btn {
    gap: 10px;
    width: 250px;
    height: 60px;
    margin-top: 20px;
    font-size: 1.1rem;
  }
  .hero-right img {
    width: 30vw;
  }
}

@media (max-width: 1024px) {
  .hero {
    height: 50vh;
    margin-bottom: 50px;
  }

  .hero-left {
    padding-left: 80px;
  }

  .hero-left h2 {
    font-size: 1.2rem;
  }

  .hero-left p {
    font-size: 2.7rem;
  }

  .hero-hand-icon img {
    width: 40px;
  }
  .hero-latest-btn {
    width: 220px;
    height: 50px;
    font-size: 1rem;
  }
  .hero-right img {
    width: 33vw;
  }
}

@media (max-width: 800px) {
  .hero {
    height: 45vh;
    margin-bottom: 30px;
  }

  .hero-left {
    padding-left: 30px;
  }

  .hero-left h2 {
    font-size: 1.1rem;
  }

  .hero-left p {
    font-size: 2.5rem;
  }

  .hero-hand-icon img {
    width: 40px;
  }

  .hero-latest-btn {
    width: 178px;
    height: 40px;
    font-size: 0.8rem;
  }

  .hero-latest-btn div {
    padding-left: 14px;
  }

  .hero-latest-btn img {
    padding-right: 14px;
    width: 16px;
  }

  .hero-right img {
    width: 35vw;
  }
}

@media (max-width: 500px) {
  .hero {
    flex-direction: column;
    height: 35vh;
    margin-bottom: 20px;
  }
  .hero-right {
    display: none;
  }

  .hero-left {
    margin: auto;
    padding-left: 0;
  }

  .hero-left h2 {
    font-size: 1.3rem;
  }

  .hero-left p {
    font-size: 1.7rem;
  }

  .hero-hand-icon img {
    width: 36px;
  }

  .hero-latest-btn {
    width: 200px;
    height: 45px;
    font-size: 0.9rem;
  }
  .hero-latest-btn div {
    padding-left: 14px;
  }
  .hero-latest-btn img {
    padding-right: 14px;
    width: 18px;
  }
}
